<template>
  <v-row class="my-2 mx-1">
    <v-col
      cols="11"
      style="border:1px solid #dbdade; border-radius:5px;"
    >
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="observation.description"
            :label="$t('lbl.observation')"
            outlined
            no-resize
            rows="3"
            row-height="15"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-row
        class="my-0"
        align="center"
        justify="center"
        style="height:100%;"
      >
        <v-col class="px-4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="error"
                @click="deleteObservationContrateHotel(index)"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapMutations } from 'vuex'
import { mdiDeleteOutline } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    observation: {
      type: Object,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
      },
    }
  },
  methods: {
    ...mapMutations(['deleteObservationContrateHotel']),
  },
}
</script>
